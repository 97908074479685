import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';
import { lastValueFrom } from 'rxjs';
import { InternalUserItem, UserListRequest } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { ApiService } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { CommonModule } from '@angular/common';
import { DxSelectBoxModule } from 'devextreme-angular';
import { MatButtonModule } from '@angular/material/button';
import { ToDoService } from '../../../modules/services/todo.service';
import { MaterialModule } from '../../../../../../goldstar-share/src/app/modules/material/material.module';
import { MatIconModule } from '@angular/material/icon';
@Component({
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, DxSelectBoxModule, MaterialModule, MatIconModule],
	selector: 'app-impersonate-user',
	templateUrl: './impersonate-user.component.html',
	styleUrls: ['./impersonate-user.component.scss'],
})
export class ImpersonateUserComponent implements OnInit {
	formGroup!: UntypedFormGroup;
	public states: any = [];
	users: InternalUserItem[] = [];
	userName!: string;
	userId = '';
	isSaveVisible = false;
	constructor(
		public authService: AuthService,
		public dialogRef: MatDialogRef<ImpersonateUserComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: UntypedFormBuilder,
		private apiV2: ApiService,
		private todoService: ToDoService
	) {}

	async ngOnInit(): Promise<void> {
		this.formGroup = this.formBuilder.group({
			email: [this.data.email, [Validators.required, this.authService.trimValidator()]],
		});
		const fetchAllUsers: UserListRequest = {
			searchFilter: [{ searchOption: 'ALL' }],
			pageSize: 1000,
			loadProfileImage: false,
		};
		this.users = await this.loadData(fetchAllUsers);
	}

	async loadData(request: any): Promise<any> {
		let response = await lastValueFrom(this.apiV2.userList({ body: request }));
		if(response.isSuccess && response.data && response.data.items) {
			let userList: InternalUserItem[] = response.data.items;
			let pageNumber: number = 1;
			while(response.data!.items!.length === 1000) {
				pageNumber++;
				response = await lastValueFrom(this.apiV2.userList({ body: { searchFilter: [{ searchOption: 'ALL' }], pageSize: 1000, pageNumber:  pageNumber }}));
				if(response.isSuccess && response.data && response.data.items) {
					userList = userList.concat(response.data.items);
				}
			}
			console.log(userList.length);
			return userList;
		} else {
			return [];
		}
	}

	onNoClick(): void {
		this.dialogRef.close({ data: false });
	}

	save(): any {
		const isFormValid = this.formGroup.valid;
		if (isFormValid) {
			let authUser = this.authService.userId;
			this.userId = this.users.find((x) => x.name === this.userName)?.userId ?? '';
			if (authUser != this.userId) {
				this.authService.startImpersonation(this.userId);
				this.todoService.updateInternalToDoData(this.userId);
				this.dialogRef.close({ data: true });
			} else {
				this.dialogRef.close({ data: true });
			}
		} else {
			this.formGroup.markAllAsTouched();
		}
	}

	userUpdate(event: Event) {
		this.isSaveVisible = false;
		if (Object.values(event).length > 0) {
			if (this.users.length > 0) {
				let isMailInList = this.users.includes(Object.values(event)[0]);
				if (isMailInList) {
					this.isSaveVisible = true;
				}
			}
		}
	}
}
